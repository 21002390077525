module.exports = {
  __init__: [ 'selectionVisuals', 'selectionBehavior' ],
  __depends__: [
    require(177),
    require(216)
  ],
  selection: [ 'type', require(237) ],
  selectionVisuals: [ 'type', require(239) ],
  selectionBehavior: [ 'type', require(238) ]
};
