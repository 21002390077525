module.exports = {
  __depends__: [
    require(117),
    require(160),
    require(240),
    require(158),
    require(164),
    require(74)
  ],
  __init__: [ 'contextPadProvider' ],
  contextPadProvider: [ 'type', require(11) ]
};