module.exports = {
  __init__: [ 'modeling', 'bpmnUpdater' ],
  __depends__: [
    require(53),
    require(27),
    require(82),
    require(70),
    require(80),
    require(128),
    require(251),
    require(181),
    require(142),
    require(240),
    require(154),
    require(247)
  ],
  bpmnFactory: [ 'type', require(28) ],
  bpmnUpdater: [ 'type', require(30) ],
  elementFactory: [ 'type', require(31) ],
  modeling: [ 'type', require(32) ],
  layouter: [ 'type', require(29) ],
  connectionDocking: [ 'type', require(258) ]
};
