module.exports = {
  __depends__: [
    require(177),
    require(240),
    require(216),
    require(234),
    require(169),
    require(224)
  ],
  __init__: [ 'move', 'movePreview' ],
  move: [ 'type', require(211) ],
  movePreview: [ 'type', require(212) ]
};
