module.exports = {
  __depends__: [
    require(140),
    require(171),
    require(175),
    require(183),
    require(247),
    require(20),
    require(14),
    require(16),
    require(84),
    require(66)
  ],
  editorActions: [ 'type', require(17) ]
};
