module.exports = {
  __depends__: [
    require(128),
    require(154),
    require(231),
    require(117)
  ],
  __init__: [
    'labelEditingProvider',
    'labelEditingPreview'
  ],
  labelEditingProvider: [ 'type', require(24) ],
  labelEditingPreview: [ 'type', require(23) ]
};
