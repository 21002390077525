module.exports = {
  __depends__: [
    require(220),
    require(164),
    require(247),
    require(183),
    require(175),
    require(255),
    require(20)
  ],
  __init__: [ 'paletteProvider' ],
  paletteProvider: [ 'type', require(71) ]
};
