'use strict';

module.exports = {
  __init__: ['spaceToolPreview'],
  __depends__: [
    require(169),
    require(234),
    require(249),
    require(224)
  ],
  spaceTool: ['type', require(244)],
  spaceToolPreview: ['type', require(245) ]
};
