module.exports = {
  __depends__: [
    require(234),
    require(169),
    require(224)
  ],
  __init__: [ 'resize', 'resizePreview', 'resizeHandles' ],
  resize: [ 'type', require(227) ],
  resizePreview: [ 'type', require(229) ],
  resizeHandles: [ 'type', require(228) ]
};
