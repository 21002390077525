'use strict';

var inherits = require(296);

var Viewer = require(3);


/**
 * A viewer that includes mouse navigation facilities
 *
 * @param {Object} options
 */
function NavigatedViewer(options) {
  Viewer.call(this, options);
}

inherits(NavigatedViewer, Viewer);

module.exports = NavigatedViewer;

NavigatedViewer.prototype._navigationModules = [
  require(267),
  require(263)
];

NavigatedViewer.prototype._modules = [].concat(
  NavigatedViewer.prototype._modules,
  NavigatedViewer.prototype._navigationModules);