'use strict';

var assign = require(438);

var BpmnModdle = require(100);

var packages = {
  bpmn: require(106),
  bpmndi: require(107),
  dc: require(108),
  di: require(109),
  bioc: require(105)
};

module.exports = function(additionalPackages, options) {
  return new BpmnModdle(assign({}, packages, additionalPackages), options);
};
